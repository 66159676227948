import { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import FormSpacer from '@client/components/form/helpers/FormSpacer';
import TextFieldInput from '@client/components/form/inputs/TextFieldInput';
import SimpleBackdrop from '@client/components/general/SimpleBackdrop';
import { useAuth } from '@client/context/auth/AuthContext';

type FormData = {
  email: '' | string;
  password: '' | string;
  formErrors: { email: ''; password: '' };
};
type Props = {
  redirectUri: string | null;
  isRedirectedFromAstroTax: boolean;
};

const LoginForm = ({ redirectUri = null, isRedirectedFromAstroTax }: Props) => {
  const { signIn, error, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<FormData>({
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const togglePassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  // Demo account setup
  useEffect(() => {
    if (isRedirectedFromAstroTax) {
      // auto login on demo account
      formMethods.setValue('email', 'demo-account@astro.tax');
      formMethods.setValue('password', 'WeloveAstro1');
      formMethods.handleSubmit(handleLogin)();
    }
  }, [isRedirectedFromAstroTax]);

  const handleLogin: SubmitHandler<FormData> = async (data, e) => {
    setErrorMessage('');
    e?.preventDefault();
    try {
      setIsLoading(true);

      await signIn(data, redirectUri);
    } catch (error: any) {
      if (error.code) {
        switch (error.code) {
          case 'auth/wrong-password':
          case 'auth/user-not-found':
          case 'auth/invalid-password':
            setErrorMessage('Deze combinatie van emailadres en wachtwoord is niet geldig');
            break;
          case 'auth/too-many-requests':
            setErrorMessage('Teveel pogingen, probeer het later nog eens');

            break;
          default:
            setErrorMessage('Er ging iets mis');
        }
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      {loading && <SimpleBackdrop />}
      {!loading && (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleLogin)}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}>
              <FormSpacer removePadding>
                <TextFieldInput
                  name={'email'}
                  label={'Email'}
                  rules={{
                    required: {
                      value: true,
                      message: 'Gelieve je email in te vullen',
                    },
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      message: 'Ongeldig email adres',
                    },
                  }}
                  TextFieldProps={{
                    type: 'email',
                    fullWidth: true,
                    disabled: isLoading,
                  }}
                />

                <TextFieldInput
                  name={'password'}
                  label={'Wachtwoord'}
                  rules={{
                    required: {
                      value: true,
                      message: 'Gelieve je wachtwoord in te vullen',
                    },
                  }}
                  TextFieldProps={{
                    type: passwordVisibility ? 'text' : 'password',
                    fullWidth: true,
                    disabled: isLoading,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => {
                              isRedirectedFromAstroTax ? null : togglePassword();
                            }}
                            edge='end'>
                            {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </FormSpacer>
              {error && (
                <Typography color='red' display='block' variant='caption'>
                  {errorMessage}
                </Typography>
              )}
              <LoadingButton
                type={'submit'}
                variant='gradient'
                loading={isLoading}
                disabled={isLoading}
                sx={{ mt: 2 }}>
                Aanmelden
              </LoadingButton>
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default LoginForm;
