import React from 'react';

import { useRouter } from 'next/router';

import LoginPanelContainer from '../components/LoginPanelContainer';
import LoginForm from './components/LoginForm';

const LoginPage = ({ redirectUri = null }: { redirectUri: string | null }) => {
  const router = useRouter();
  const queryParams = router.query;
  const isDemoAccount = queryParams.demoAccount === 'true';
  const isRedirectedFromAstroTax = isDemoAccount;
  return (
    <>
      <LoginPanelContainer>
        <LoginForm isRedirectedFromAstroTax={isRedirectedFromAstroTax} redirectUri={redirectUri} />
      </LoginPanelContainer>
    </>
  );
};

export default LoginPage;
